import React from "react";
import MagicIcon from "../assets/Magic-icon.svg";
import { useAnimation } from "../context/AnimationContext";
import { useCart } from "../context/CartContext";
import { Product as ProductModel } from "../types/product";
import { tryProduct } from "../utils/tryOnImage";
import { AlreadyAddedButton } from "./AlreadyAddedButton";
import { AddToCartButton } from "./CartButton";
import ItemCard from "./ItemCard";
import ItemsCarousel from "./ItemsCarousel";

interface ProductProps {
  productData: ProductModel[];
}

const Product: React.FC<ProductProps> = ({ productData }) => {
  const { addToCart, cart } = useCart();
  const { loadingStates, imageData, triggerAnimation } = useAnimation();
  const isProductInCart = (productName: string): boolean => {
    return cart?.listItems.some((item) => item.name === productName) || false;
  };

  return (
    <>
      {productData.length === 1 ? (
        <div
          className="w-full flex justify-center items-center px-4 py-4"
          style={{ maxHeight: "75vh" }}
        >
          <div className="w-full flex justify-center items-center  mb-24">
            <div className="w-[321px] bg-white rounded-lg shadow-lg border border-gray-200 relative">
              {loadingStates[productData[0].id] ? (
                <div
                  className=" w-full h-[75vh] animate-magicPulse inset-0 rounded-lg z-10 shadow-inner opacity-50"
                  style={{
                    background: `linear-gradient(135deg, #FF944D 0%, #FB81BA 33.33%, #D98BFB 66.67%, #FF944D 100%)`,
                  }}
                ></div>
              ) : (
                <>
                  <img
                    src={
                      !imageData[productData[0].id] ||
                      imageData[productData[0].id] === "undefined" ||
                      imageData[productData[0].id].includes("undefined")
                        ? productData[0].image
                        : imageData[productData[0].id]
                    }
                    alt={productData[0].name}
                    className="w-full h-[55vh] object-cover rounded-t-lg"
                    loading="lazy"
                  />
                  <button
                    onClick={() =>
                      triggerAnimation(productData[0].id, () =>
                        tryProduct(productData[0].id)
                      )
                    }
                    className="absolute bottom-32 right-4 bg-custom-light-gray px-4 py-3 rounded-lg flex items-center space-x-3 opacity-75"
                  >
                    Essayer
                    <img
                      src={MagicIcon}
                      alt="Magic Icon"
                      className="w-18 h-18 ml-1"
                    />
                  </button>
                  <div className="p-4 bg-[#ecedef] backdrop-blur-[13.50px] rounded-b-lg flex items-center justify-between">
                    <div>
                      <h2 className="text-black text-lg font-medium font-sans">
                        {productData[0].name}
                      </h2>
                      <p className="text-[#110b19] text-sm font-normal font-sans mb-2">
                        {productData[0].brand}
                      </p>
                      <p className="text-black text-2xl font-semibold">
                        {productData[0].amount.centAmount.toLocaleString("fr", {
                          style: "currency",
                          currency: productData[0].amount.currencyCode,
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                    <div className="bg-white rounded-xl shadow-md text-[#110B19] hover:bg-gray-100">
                      {isProductInCart(productData[0].name) ? (
                        <AlreadyAddedButton />
                      ) : (
                        <AddToCartButton
                          onAddToCart={() => addToCart(productData[0].id)}
                        />
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <ItemsCarousel>
          {productData.map((product) => {
            return (
              <ItemCard key={product.id}>
                {loadingStates[product.id] ? (
                  <div
                    className=" w-full h-[75vh] animate-magicPulse inset-0 rounded-lg z-10 shadow-inner opacity-50"
                    style={{
                      background: `linear-gradient(135deg, #FF944D 0%, #FB81BA 33.33%, #D98BFB 66.67%, #FF944D 100%)`,
                    }}
                  ></div>
                ) : (
                  <>
                    <img
                      src={
                        !imageData[product.id] ||
                        imageData[product.id] === "undefined" ||
                        imageData[product.id].includes("undefined")
                          ? product.image
                          : imageData[product.id]
                      }
                      alt={product.name}
                      className="w-full h-[55vh] object-cover rounded-t-lg"
                      loading="lazy"
                    />
                    <button
                      onClick={() =>
                        triggerAnimation(product.id, () =>
                          tryProduct(product.id)
                        )
                      }
                      className="absolute bottom-32 right-4 bg-custom-light-gray px-4 py-3 rounded-lg flex items-center space-x-3 opacity-75"
                    >
                      Essayer
                      <img
                        src={MagicIcon}
                        alt="Magic Icon"
                        className="w-18 h-18 ml-1"
                      />
                    </button>
                    <div className="p-4 bg-[#ecedef] backdrop-blur-[13.50px]  rounded-b-lg flex items-center justify-between">
                      <div>
                        <h2 className="text-black text-lg font-medium font-sans">
                          {product.name}
                        </h2>
                        <p className="text-[#110b19] text-sm font-normal font-sans mb-2">
                          {product.brand}
                        </p>
                        <p className="text-black text-2xl font-semibold">
                          {product.amount.centAmount.toLocaleString("fr", {
                            style: "currency",
                            currency: product.amount.currencyCode,
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2,
                          })}
                        </p>
                      </div>
                      <div className="bg-white rounded-xl shadow-md text-[#110B19]">
                        {isProductInCart(product.name) ? (
                          <AlreadyAddedButton />
                        ) : (
                          <AddToCartButton
                            onAddToCart={() => addToCart(product.id)}
                          />
                        )}
                      </div>
                    </div>
                  </>
                )}
              </ItemCard>
            );
          })}
        </ItemsCarousel>
      )}
    </>
  );
};

export default Product;
