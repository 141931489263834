import { createContext, useContext } from "react";
type ImageData = Record<string, string>;
type LoadingStates = Record<string, boolean>;

interface AnimationContextType {
  loadingStates: LoadingStates;
  imageData: ImageData;
  triggerAnimation: (id: string, fetchFunction: () => Promise<string>) => void;
  clearImage: () => void;
}

export const AnimationContext = createContext<AnimationContextType | undefined>(
  undefined
);

export const useAnimation = (): AnimationContextType => {
  const context = useContext(AnimationContext);
  if (!context) {
    throw new Error("useAnimation must be used within an AnimationProvider");
  }
  return context;
};
