import { FormattedItem } from "openai-realtime-api";
import { createContext, useContext } from "react";

export interface ConversationContextType {
  conversations: FormattedItem[];
  isConversationsModalOpen: boolean;
  toggleConversations: () => void;
  updateConversations: (conversations: FormattedItem[]) => void;
  clearConversations: (conversations: FormattedItem[]) => void;
}

export const ConversationContext = createContext<ConversationContextType>({
  conversations: [],
  isConversationsModalOpen: false,
  toggleConversations: () => {},
  updateConversations: () => {},
  clearConversations: () => {},
});

export const useConversations = () => {
  return useContext(ConversationContext);
};
