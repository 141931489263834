import { createContext, useContext } from "react";

export const AuthContext = createContext<AuthContextType>({
  token: null,
  authData: null,
  login: async () => {},
  logout: () => {},
});

export interface AuthContextType {
  token: string | null;
  authData: authDataType | null;
  login: (username: string, password: string) => Promise<void>;
  logout: () => void;
}

export interface authDataType {
  id: string;
  firstName: string;
}

export const useAuth = () => {
  return useContext(AuthContext);
};
