import { Route, Routes } from "react-router-dom";
import "./App.css";
import UserPicture from "./components/UserPicture";
import AddPhotoPage from "./pages/AddPhotoPage";
import AssistantPage from "./pages/AssistantPage";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import PictureVisualizer from "./pages/PictureVisualizer";
import PTAssistant from "./pages/PTAssistant";
import TermsAndConditions from "./pages/TermsAndConditions";
import UploadPhoto from "./pages/UploadPhoto";
import PrivateRoute from "./router/route";
function App() {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route element={<PrivateRoute />}>
        <Route path="/" element={<HomePage />} />
        <Route path="/assistant-ui" element={<AssistantPage />} />
        <Route path="/pt-assistant-ui" element={<PTAssistant />} />
        <Route path="/add-photo" element={<AddPhotoPage />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/user-picture" element={<UserPicture />} />
        <Route path="/upload-picture" element={<UploadPhoto />} />
        <Route path="/picture-visualizer" element={<PictureVisualizer />} />
      </Route>
    </Routes>
  );
}

export default App;
