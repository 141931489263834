import { jwtDecode } from "jwt-decode";
import { FC, PropsWithChildren, useEffect, useState } from "react";
import {
  AuthContext,
  AuthContextType,
  authDataType,
} from "../context/AuthContext";

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [token, setToken] = useState<string | null>(
    localStorage.getItem("auth_token")
  );
  const [authData, setAuthData] = useState<authDataType | null>(() => {
    const storedData = localStorage.getItem("auth_data");
    return storedData ? JSON.parse(storedData) : null;
  });

  const logout = (): void => {
    localStorage.removeItem("auth_token");
    localStorage.removeItem("auth_data");
    setToken(null);
    setAuthData(null);
    window.location.href = "/login";
  };

  useEffect(() => {
    if (token) {
      try {
        const { exp } = jwtDecode<{ exp: number }>(token);
        const timeLeft = exp * 1000 - Date.now();

        if (timeLeft <= 0) {
          logout();
        } else {
          const timeout = setTimeout(() => {
            logout();
          }, timeLeft);

          return () => clearTimeout(timeout);
        }
      } catch (error) {
        console.error("Error decoding token:", error);
        logout();
      }
    }
  }, [token]);

  const login = async (username: string, password: string): Promise<void> => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BFF_URL}/auth/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username,
            password,
          }),
        }
      );

      if (!response.ok) {
        const errorBody = await response.json();
        const errorMessage =
          errorBody.message || "An error occurred during login.";
        throw new Error(errorMessage);
      }

      const res = await response.json();

      if (res.access_token) {
        const { exp } = jwtDecode<{ exp: number }>(res.access_token);
        if (Date.now() >= exp * 1000) {
          throw new Error(
            "Received expired token. Please try logging in again."
          );
        }

        setToken(res.access_token);
        setAuthData({ id: res.id, firstName: res.firstName });
        localStorage.setItem("auth_token", res.access_token);
        localStorage.setItem(
          "auth_data",
          JSON.stringify({ id: res.id, firstName: res.firstName })
        );
      }
    } catch (error: any) {
      console.error("Login failed:", error);
      throw new Error(
        error.message || "An unknown error occurred during login."
      );
    }
  };

  const contextValue: AuthContextType = {
    token,
    authData,
    login,
    logout,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
