import { ReactNode, useState } from "react";
import { AnimationContext } from "../context/AnimationContext";

type ImageData = Record<string, string>;
type LoadingStates = Record<string, boolean>;

export const AnimationProvider = ({ children }: { children: ReactNode }) => {
  const [loadingStates, setLoadingStates] = useState<LoadingStates>({});
  const [imageData, setImageData] = useState<ImageData>({});

  const triggerAnimation = async (
    id: string,
    fetchFunction: () => Promise<string>
  ) => {
    setLoadingStates((prev) => ({ ...prev, [id]: true }));
    try {
      const image = await fetchFunction();
      setImageData((prev) => ({ ...prev, [id]: image }));
    } catch (error) {
      console.error(`Failed to fetch image for ${id}:`, error);
    } finally {
      setLoadingStates((prev) => ({ ...prev, [id]: false }));
    }
  };

  const clearImage = async (): Promise<void> => {
    setImageData({});
  };

  return (
    <AnimationContext.Provider
      value={{ loadingStates, imageData, triggerAnimation, clearImage }}
    >
      {children}
    </AnimationContext.Provider>
  );
};
