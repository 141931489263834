import { useCallback, useEffect, useState } from "react";
import AssistantHeader from "../components/AssisantHeader";
import CartScreen from "../components/CartScreen";
import { InteractionStatusText } from "../components/DynamicStatusMessage";
import { DefaultFooter } from "../components/Footer";
import { RealtimeBubble } from "../components/GradientBubbles";
import SuggestedItems from "../components/SuggestedItems";
import { TranscriptedConversation } from "../components/TranscriptedConversation";
import { useAnimation } from "../context/AnimationContext";
import { useCart } from "../context/CartContext";
import { useConversations } from "../context/ConversationContext";
import { useAudioProcessing } from "../hooks/useAudioProcessing";
import { useRealtimeClient } from "../hooks/useRealtimeClient";

const AssistantPage = () => {
  const [showText, setShowText] = useState(true);
  const [bubblePosition, setBubblePosition] = useState("center");

  const { clearCart, toggleCart, isCartOpen } = useCart();
  const { clearImage } = useAnimation();
  const [isPressed, setIsPressed] = useState(false);
  const { isConversationsModalOpen, toggleConversations, updateConversations } =
    useConversations();

  const {
    clientRef,
    data,
    hasSpoken,
    isLoading,
    wavRecorderRef,
    wavStreamPlayerRef,
    connectConversation,
    disconnectConversation,
    handleConversationUpdate,
  } = useRealtimeClient({ setShowText, updateConversations });

  const { pulseClass } = useAudioProcessing({
    wavStreamPlayerRef,
    wavRecorderRef,
    isPressed,
    setShowText,
  });

  useEffect(() => {
    connectConversation();
    return () => {
      disconnectConversation();
    };
  }, [connectConversation, disconnectConversation]);

  useEffect(() => {
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const client = clientRef.current;
    client.on("error", (event: any) => console.error(event));
    client.on("conversation.interrupted", async () => {
      const trackSampleOffset = await wavStreamPlayer.interrupt();
      if (trackSampleOffset?.trackId) {
        const { trackId, offset } = trackSampleOffset;
        await client.cancelResponse(trackId, offset);
      }
    });
    client.on("conversation.updated", handleConversationUpdate);
    updateConversations(client.conversation.getItems());
    return () => {
      clearCart();
      client.off("conversation.updated", handleConversationUpdate);
    };
  }, [handleConversationUpdate]);

  const onMute = useCallback(async () => {
    try {
      const wavRecorder = wavRecorderRef.current;

      if (wavRecorder.getStatus() === "recording") {
        await wavRecorder.pause();
      }
    } catch (error) {
      console.error("error while muting", error);
    }
  }, []);

  const onUnMute = useCallback(async () => {
    try {
      const wavRecorder = wavRecorderRef.current;
      if (wavRecorder.getStatus() === "paused") {
        await wavRecorder.record((data) =>
          clientRef.current.appendInputAudio(data.mono)
        );
      }
    } catch (error) {
      console.error("error while unmuting", error);
    }
  }, []);

  useEffect(() => {
    if (isCartOpen || data || isConversationsModalOpen) {
      setBubblePosition("bottom");
      if (data) {
        clearImage();
      }
    } else {
      setBubblePosition("center");
    }
    return () => {
      clearImage();
      if (isCartOpen) {
        toggleCart();
      }
      if (isConversationsModalOpen) {
        toggleConversations();
      }
    };
  }, [isCartOpen, data, isConversationsModalOpen]);

  return (
    <div className="relative w-full h-screen-safe   pb-[calc(16px+env(safe-area-inset-bottom))] flex justify-center items-center overflow-hidden bg-custom-light-gray">
      <AssistantHeader />
      {isConversationsModalOpen && <TranscriptedConversation />}
      {isCartOpen && <CartScreen />}
      {data && <SuggestedItems data={data} />}
      <InteractionStatusText
        isLoading={isLoading}
        showText={showText}
        bubblePosition={bubblePosition}
        hasSpoken={hasSpoken}
      />
      <RealtimeBubble position={bubblePosition} pulseClass={pulseClass} />
      <DefaultFooter onMute={onMute} onUnMute={onUnMute} />
    </div>
  );
};

export default AssistantPage;
