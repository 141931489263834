import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"; // Import useNavigate
import { toast } from "react-toastify";
import PictoIcon from "../assets/Picto.svg";
import BackNavigation from "../components/BackNavigation";
import Loader from "../components/Loader";
import { useAuth } from "../context/AuthContext";
import { useUser } from "../context/UserContext";

const UploadPhoto = () => {
  const [file, setFile] = useState<File | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [uploading, setUploading] = useState(false);

  const validImageTypes = ["image/jpeg", "image/png"];
  const { logout } = useAuth();

  const { user, fetchUser } = useUser();
  const navigate = useNavigate(); // Initialize navigate
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const isEditMode = searchParams.get("edit") === "true";

  useEffect(() => {
    if (user?.photoUrl && !isEditMode) {
      navigate("/", { replace: true });
    }
  }, [user, isEditMode, navigate]);

  const handleFileValidation = (selectedFile: File | null) => {
    if (!selectedFile) return false;
    if (!validImageTypes.includes(selectedFile.type)) {
      toast.error("Seules les images (JPG, PNG) sont acceptées.");
      return false;
    }
    return true;
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile && handleFileValidation(selectedFile)) {
      setFile(selectedFile);
    }
  };

  const handleFileDrop = (files: FileList) => {
    const droppedFile = files[0];
    if (droppedFile && handleFileValidation(droppedFile)) {
      setFile(droppedFile);
    }
  };

  const removeImage = () => {
    setFile(null);
  };

  const uploadImage = async () => {
    if (!file) {
      toast.error("Aucun fichier sélectionné pour le téléchargement.");
      return;
    }
    if (!user) {
      throw new Error("No user data");
      logout();
    }

    setUploading(true);

    try {
      const formData = new FormData();
      formData.append("file", file);

      const token = localStorage.getItem("auth_token");

      const url = `${process.env.REACT_APP_BFF_URL}/customers/${user?.id}/upload-profile-image`;

      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Échec du téléchargement de l'image");
      }

      const data = await response.json();
      await fetchUser();
      navigate("/picture-visualizer");
      toast.success("Votre photo a été bien ajoutée!");
      console.log("Uploaded Image URL:", data.imageUrl);
    } catch (error) {
      console.error("Error uploading image:", error);
      toast.error("Échec du téléchargement de l'image. Veuillez réessayer!");
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="h-screen flex flex-col bg-custom-light-gray">
      <BackNavigation />
      {uploading && <Loader />}
      <div className="flex-1 overflow-y-auto">
        <div className=" relative flex flex-col items-center px-4 pt-6 bg-custom-light-gray">
          {!file && (
            <div
              className={`flex flex-col items-center justify-center w-full h-40 sm:h-44 md:h-48 lg:h-52 border-2 rounded-md border-dashed ${
                isDragging ? "border-blue-500 bg-blue-50" : "border-gray-300"
              } hover:bg-gray-100`}
              onDragOver={(e) => {
                e.preventDefault();
                setIsDragging(true);
              }}
              onDragLeave={() => setIsDragging(false)}
              onDrop={(e) => {
                e.preventDefault();
                setIsDragging(false);
                handleFileDrop(e.dataTransfer.files);
              }}
            >
              <div className="relative flex flex-col items-center justify-center p-4">
                <img
                  src={PictoIcon}
                  alt="Upload Icon"
                  className="w-10 sm:w-12"
                />
                <p className="text-sm text-gray-400 mt-2">
                  Drag and drop a photo or click to upload
                </p>
              </div>
              <input
                type="file"
                onChange={handleFileChange}
                className="opacity-0 absolute inset-0 cursor-pointer"
                accept="image/*"
              />
            </div>
          )}
          {file && (
            <div className="relative flex flex-col items-center mt-6">
              <img
                src={URL.createObjectURL(file)}
                alt="Uploaded Preview"
                className="w-full object-contain rounded-md"
                style={{ maxHeight: "75vh" }}
              />
            </div>
          )}
        </div>
        {file && (
          <footer className="px-6 py-4 bg-custom-light-gray">
            <div className="flex flex-col items-center space-y-3">
              <button
                className={`w-full bg-black text-white py-3 rounded-2xl font-medium shadow-md hover:bg-gray-800 transition leading-tight max-w-sm ${
                  uploading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                onClick={uploadImage}
                disabled={uploading}
              >
                {uploading ? "Uploading..." : "Utiliser la Photo"}
              </button>
              <button
                className="w-full bg-gray-100 text-dark-purple py-3 rounded-2xl mt-3 font-medium hover:bg-gray-200 transition border border-[#9ea1a8] leading-tight max-w-sm"
                onClick={removeImage}
              >
                Supprimer
              </button>
            </div>
          </footer>
        )}
      </div>
    </div>
  );
};

export default UploadPhoto;
