import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import "./index.css";
import { AnimationProvider } from "./providers/AnimationProvider";
import { AuthProvider } from "./providers/AuthProvider";
import { CartProvider } from "./providers/CartProvider";
import { ConversationProvider } from "./providers/ConversationProvider";
import { UserProvider } from "./providers/UserProvider";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <UserProvider>
        <AnimationProvider>
          <CartProvider>
            <ConversationProvider>
              <BrowserRouter>
                <ToastContainer position="top-center" autoClose={3000} />
                <App />
              </BrowserRouter>
            </ConversationProvider>
          </CartProvider>
        </AnimationProvider>
      </UserProvider>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
