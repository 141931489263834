import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BackNavigation from "../components/BackNavigation";

const TermsAndConditionsPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleValidate = () => {
    localStorage.setItem("hasAcceptedTerms", "true");
    navigate("/upload-picture");
  };

  useEffect(() => {
    const previousPath = location.state?.from;
    if (!previousPath || localStorage.getItem("hasAcceptedTerms")) {
      navigate("/add-photo");
    }
  }, [location, navigate]);

  const handleCancel = () => {
    navigate("/add-photo");
  };

  return (
    <div className=" w-full relative flex flex-col  h-screen-safe   pb-[calc(16px+env(safe-area-inset-bottom))]   sm:pb-10 overflow-hidden flex-shrink-0  top-0 mt-0 bg-custom-light-gray">
      <BackNavigation />
      <main className="flex-grow px-6 py-6 space-y-4 overflow-auto">
        <h1 className="text-center text-lg font-semibold">
          Termes et conditions
        </h1>
        <p className="text-sm text-gray-800 leading-relaxed">
          Dans le cadre de l’expérience proposée par le Personal Shopper,
          certaines données personnelles, y compris la photo de l’utilisateur,
          peuvent être collectées afin d’améliorer et personnaliser le parcours
          d’achat.
        </p>
        <p className="text-sm text-gray-800 leading-relaxed">
          En cliquant sur continuer je déclare être informé et consentir aux
          points suivants :
        </p>
        <ul className="text-sm text-gray-800 list-disc pl-5 space-y-2">
          <li>
            Ma photo peut être utilisée pour réaliser l’essayage virtuel des
            produits lors de l’étape dédiée dans l’application.
          </li>
          <li>
            La photo sera traitée temporairement pendant l’essayage et ne sera
            pas conservée après cette étape, sauf accord explicite de ma part
            pour une utilisation ultérieure.
          </li>
        </ul>
      </main>
      <footer className="px-6 py-4 space-y-4">
        <button
          onClick={handleValidate}
          className="w-full bg-black text-white py-3 rounded-2xl font-medium shadow-md hover:bg-gray-800 transition leading-tight"
        >
          Accepter et continuer
        </button>
        <button
          onClick={handleCancel}
          className="w-full bg-gray-100 text-dark-purple py-3 rounded-2xl mt-3 font-medium  hover:bg-gray-200 transition  border border-[#9ea1a8] leading-tight"
        >
          Refuser
        </button>
      </footer>
    </div>
  );
};

export default TermsAndConditionsPage;
