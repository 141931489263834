import { RealtimeClient } from "openai-realtime-api";
import { MutableRefObject, useEffect } from "react";
import { tryProduct, tryStyle } from "../utils/tryOnImage";

interface UseClientToolsProps {
  clientRef: MutableRefObject<RealtimeClient>;
  addToCart: (id: string) => void;
  triggerAnimation: (id: string, fetchFunction: () => Promise<string>) => void;
}

export const UseClientTools = ({
  clientRef,
  addToCart,
  triggerAnimation,
}: UseClientToolsProps): void => {
  const client = clientRef.current;
  useEffect(() => {
    client.addTool(
      {
        name: "add_to_cart",
        description:
          "Ajoute le produit au panier en envoyant son identifiant, utilise bien l'attribut 'id'",
        parameters: {
          type: "object",
          properties: {
            id: {
              type: "string",
              description: "Identifiant (ID) du produit à ajouter",
            },
          },
          required: ["id"],
        },
      },
      async ({ id }) => {
        try {
          if (!id) {
            throw new Error("Invalid product ID");
          }
          addToCart(id);
        } catch (error: any) {
          console.error("Failed to add to cart:", error);
        }
      }
    );

    client.addTool(
      {
        name: "add_style_products_to_cart",
        description:
          "Ajoute tous les produits associés à un style au panier en envoyant les identifiants des produits associés.",
        parameters: {
          type: "object",
          properties: {
            id: { type: "string", description: "Identifiant (ID) du style" },
            associated_products: {
              type: "array",
              items: {
                type: "string",
                description: "détails sur les produits associés",
              },
              description: "Liste des  des produits associés",
            },
          },
          required: ["id", "associated_products"],
        },
      },
      async ({ id, associated_products }) => {
        try {
          if (
            !Array.isArray(associated_products) ||
            associated_products.length === 0
          ) {
            console.warn(`No associated products provided for style ID ${id}.`);
            return;
          }

          for (const product of associated_products) {
            try {
              await addToCart(product.id);
            } catch (error) {
              console.error(
                `Failed to add product ${product.id} to cart:`,
                error
              );
            }
          }
        } catch (error) {
          console.error("Error adding style products to cart:", error);
        }
      }
    );

    client.addTool(
      {
        name: "try_product",
        description:
          "Essayer le produit sur une image en envoyant son identifiant, utilise bien l'attribut 'id'",
        parameters: {
          type: "object",
          properties: {
            id: {
              type: "string",
              description: "Identifiant (ID) du produit à essayer",
            },
          },
          required: ["id"],
        },
      },
      async ({ id }: { id: string }) => {
        try {
          await triggerAnimation(id, () => tryProduct(id));
        } catch (error) {
          console.error(
            "Error fetching product image in AssistantPage:",
            error
          );
        }
      }
    );

    client.addTool(
      {
        name: "try_style",
        description:
          "Essayer le style sur une image en envoyant son identifiant, utilise bien l'attribut 'id'",
        parameters: {
          type: "object",
          properties: {
            id: {
              type: "string",
              description: "Identifiant (ID) du style à essayer",
            },
          },
          required: ["id"],
        },
      },
      async ({ id }: { id: string }) => {
        try {
          await triggerAnimation(id, () => tryStyle(id));
        } catch (error) {
          console.error("Error fetching style image in AssistantPage:", error);
        }
      }
    );
  }, []);
};
