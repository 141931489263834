import React from "react";
import { DarkCartButton } from "./CartButton";
import { DefaultMicrophone } from "./MicrophoneButton";

interface FooterProps {
  onMute(): void;
  onUnMute(): void;
}

export const DefaultFooter: React.FC<FooterProps> = ({ onMute, onUnMute }) => {
  return (
    <div className="absolute bottom-[30px] w-full flex justify-between px-8 py-4">
      <DefaultMicrophone onMute={onMute} onUnMute={onUnMute} />
      <DarkCartButton />
    </div>
  );
};

export const PTFooter = () => {
  return (
    <div className="absolute bottom-[30px] w-full flex justify-end px-8 py-4">
      <DarkCartButton />
    </div>
  );
};

export const MainFooter = () => {
  return (
    <footer className="absolute bottom-6 text-gray-500 text-sm">
      Powered by
      <a
        href="https://orkester.fr/"
        target="_blank"
        rel="noopener noreferrer"
        className="font-semibold no-underline"
      >
        {" "}
        Orkester{" "}
      </a>{" "}
      x
      <a
        href="https://commercetools.com"
        target="_blank"
        rel="noopener noreferrer"
        className="font-semibold no-underline"
      >
        {" "}
        Commercetools{" "}
      </a>{" "}
      x
      <a
        href="https://openai.com"
        target="_blank"
        rel="noopener noreferrer"
        className="font-semibold no-underline"
      >
        {" "}
        OpenAI{" "}
      </a>
    </footer>
  );
};
