import React from "react";
import Loader from "./Loader";
interface ConfirmationModalProps {
  onCancel: () => void;
  onDelete: () => void;
  deleting: boolean;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  onCancel,
  onDelete,
  deleting,
}) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-40 z-50">
      {deleting && <Loader />}

      <div className="bg-white rounded-2xl shadow-md p-6 w-[90%] max-w-sm">
        <h2 className="text-lg font-semibold text-gray-900">
          Supprimer votre photo ?
        </h2>
        <p className="text-sm text-gray-600 mt-2">
          Vous ne pourrez plus utiliser la fonctionnalité d’essayage virtuel.
        </p>
        <div className="flex justify-between mt-6">
          <button
            className="px-6 py-2 rounded-full bg-gray-900 text-white font-medium hover:bg-gray-700 transition"
            onClick={onCancel}
            disabled={deleting}
          >
            Annuler
          </button>
          <button
            className="px-6 py-2 rounded-full border border-gray-300 text-gray-900 font-medium hover:bg-gray-100 transition"
            onClick={onDelete}
            disabled={deleting}
          >
            {deleting ? "Suppression..." : "Supprimer"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
