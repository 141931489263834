import { useNavigate } from "react-router-dom";
import UserIcon from "../assets/Account.svg";

export const UserAccountButton = () => {
  const navigate = useNavigate();

  const handleClick = (navigateTo: string) => {
    navigate(navigateTo);
  };

  return (
    <button
      className="absolute top-10 right-6 mb-2"
      onClick={() => handleClick("/user-picture")}
    >
      <img src={UserIcon} alt="User Icon" className="w-10 h-10" />
    </button>
  );
};
