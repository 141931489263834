import HistoryIcon from "../assets/History.svg";
import { useConversations } from "../context/ConversationContext";

export const HistoryButton = () => {
  const { toggleConversations } = useConversations();

  const handleClick = () => {
    toggleConversations();
  };

  return (
    <button className="absolute top-2 right-12 mb-4" onClick={handleClick}>
      <img src={HistoryIcon} alt="History Icon" />
    </button>
  );
};
