import LightCartIcon from "../assets/AddShoppingCart.svg";
import CartIcon from "../assets/Cart.svg";
import { useCart } from "../context/CartContext";

interface DarkCartButton {
  onAddToCart(): void;
}

interface AddToCartCartButton {
  onAddToCart(): void;
}
export const DarkCartButton = () => {
  const { cart, isCartOpen, toggleCart } = useCart();

  const itemCount =
    cart?.listItems.reduce((sum, item) => sum + item.qty, 0) || 0;

  const iconClass = `flex items-center justify-center w-16 h-16 rounded-xl flex-shrink-0 relative ${
    itemCount > 0 ? "bg-dark" : "bg-dark-custom-light-gray"
  }`;

  return (
    <button
      disabled={itemCount === 0}
      onClick={toggleCart}
      className={iconClass}
    >
      <img src={CartIcon} alt="Cart Icon" className="w-18 h-18" />
      {itemCount > 0 && (
        <div className="absolute -top-1 -right-1 bg-custom-pink text-white rounded-full w-5 h-5 flex items-center justify-center text-xs mt-1.5 mr-2">
          {itemCount}
        </div>
      )}
    </button>
  );
};

export const AddToCartButton: React.FC<AddToCartCartButton> = ({
  onAddToCart,
}) => {
  return (
    <button
      onClick={onAddToCart}
      className={`flex items-center justify-center w-12 h-12 rounded-xl flex-shrink-0 bg-dark-custom-light-gray  hover:bg-gray-100`}
    >
      <img src={LightCartIcon} alt="Add to cart Icon" className="w-8 h-8" />
    </button>
  );
};
