import { useEffect, useState } from "react";
import AssistantHeader from "../components/AssisantHeader";
import CartScreen from "../components/CartScreen";
import { PTInteractionStatusText } from "../components/DynamicStatusMessage";
import { PTFooter } from "../components/Footer";
import { PushToTalkBubble } from "../components/GradientBubbles";
import SuggestedItems from "../components/SuggestedItems";
import { TranscriptedConversation } from "../components/TranscriptedConversation";
import { useAnimation } from "../context/AnimationContext";
import { useCart } from "../context/CartContext";
import { useConversations } from "../context/ConversationContext";
import { useAudioProcessing } from "../hooks/useAudioProcessing";
import { usePushToTalk } from "../hooks/usePusthToTalk";

const PTAssistant = () => {
  const [showText, setShowText] = useState(true);
  const [bubblePosition, setBubblePosition] = useState("center");

  const { isCartOpen, toggleCart, clearCart } = useCart();
  const { clearImage } = useAnimation();

  const [isPressed, setIsPressed] = useState(false);
  const [isAssistantSpeaking, setIsAssistantSpeaking] = useState(false);
  const { isConversationsModalOpen, toggleConversations, updateConversations } =
    useConversations();

  const {
    clientRef,
    data,
    isLoading,
    wavRecorderRef,
    wavStreamPlayerRef,
    isConnected,
    connectConversation,
    disconnectConversation,
    handleConversationUpdate,
  } = usePushToTalk({
    bubblePosition,
    setBubblePosition,
    setIsAssistantSpeaking,
    updateConversations,
  });

  const { pulseClass } = useAudioProcessing({
    wavStreamPlayerRef,
    wavRecorderRef,
    isPressed,
    setShowText,
    enableInactivityTimer: false,
  });

  const startRecording = async () => {
    const wavRecorder = wavRecorderRef.current;
    if (wavRecorder.getStatus() === "recording") {
      await wavRecorder.pause();
    }
    setIsPressed(true);
    setBubblePosition("bottom");
    const client = clientRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const trackSampleOffset = await wavStreamPlayer.interrupt();
    if (trackSampleOffset?.trackId) {
      const { trackId, offset } = trackSampleOffset;
      await client.cancelResponse(trackId, offset);
    }
    await wavRecorder.record((data) => client.appendInputAudio(data.mono));
  };

  const stopRecording = async () => {
    setIsPressed(false);
    if (data || isCartOpen) {
      setBubblePosition("bottom");
    } else {
      setBubblePosition("center");
    }
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    await wavRecorder.pause();
    client.createResponse();
  };

  useEffect(() => {
    connectConversation();

    return () => {
      disconnectConversation();
    };
  }, [connectConversation, disconnectConversation]);

  useEffect(() => {
    const client = clientRef.current;
    client.updateSession({
      input_audio_transcription: { model: "whisper-1" },
      turn_detection: null,
    });

    client.on("error", (event: any) => console.error(event));
    client.on("conversation.interrupted", async () => {
      const trackSampleOffset = await wavStreamPlayerRef.current.interrupt();
      if (trackSampleOffset?.trackId) {
        const { trackId, offset } = trackSampleOffset;
        await client.cancelResponse(trackId, offset);
      }
    });

    wavStreamPlayerRef.current.onPlaybackEnd = () => {
      setBubblePosition("end");
      setIsAssistantSpeaking(false);
      //setIsPressed(false);
    };

    client.on("conversation.updated", handleConversationUpdate);
    updateConversations(client.conversation.getItems());

    return () => {
      clearCart();
      client.off("conversation.updated", handleConversationUpdate);
      client.reset();
    };
  }, [handleConversationUpdate]);

  useEffect(() => {
    if (isCartOpen || data || isConversationsModalOpen) {
      setBubblePosition("bottom");
      if (data) {
        clearImage();
      }
    } else {
      if (isAssistantSpeaking) {
        setBubblePosition("center");
      }
    }
    return () => {
      clearImage();
      if (isCartOpen) {
        toggleCart();
      }
      if (isConversationsModalOpen) {
        toggleConversations();
      }
    };
  }, [isCartOpen, data, isConversationsModalOpen, isAssistantSpeaking]);

  return (
    <div className="relative w-full h-screen-safe   pb-[calc(16px+env(safe-area-inset-bottom))] flex justify-center items-center overflow-hidden bg-custom-light-gray">
      <AssistantHeader />
      {isCartOpen && <CartScreen />}
      {isConversationsModalOpen && <TranscriptedConversation />}
      {data && <SuggestedItems data={data} />}
      <PTInteractionStatusText
        isLoading={isLoading}
        isPressed={isPressed}
        isAssistantSpeaking={isAssistantSpeaking}
        bubblePosition={bubblePosition}
      />

      <PushToTalkBubble
        bubblePosition={bubblePosition}
        pulseClass={pulseClass}
        disabled={isAssistantSpeaking || isCartOpen}
        startRecording={startRecording}
        stopRecording={stopRecording}
      />

      <PTFooter />
    </div>
  );
};

export default PTAssistant;
