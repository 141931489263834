import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PictoIcon from "../assets/Picto.svg";
import { useUser } from "../context/UserContext";

const AddPhotoPage: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = (navigateTo: string) => {
    navigate(navigateTo);
  };

  const { user } = useUser();

  useEffect(() => {
    if (user?.photoUrl) {
      navigate("/", { replace: true });
    }
  }, [user, navigate]);

  const handleAddPhotoClick = () => {
    const hasAcceptedTerms = localStorage.getItem("hasAcceptedTerms");

    if (!hasAcceptedTerms) {
      navigate("/terms-and-conditions", { state: { from: "add-photo-page" } });
    } else {
      navigate("/upload-picture");
    }
  };

  return (
    <div className=" w-full relative flex flex-col items-center justify-center h-screen-safe   pb-[calc(16px+env(safe-area-inset-bottom))] text-center  sm:pb-10 overflow-hidden flex-shrink-0  top-0 mt-0">
      <div className="bg-radial-gradient top-0 mt-0 "></div>
      <div className="fixed inset-0 z-50 flex items-center justify-center">
        <div className="bg-white rounded-2xl shadow-2xl p-6 max-w-md w-full text-left relative mx-4 sm:mx-auto">
          <div className="flex flex-col items-center">
            <div className="w-16 h-16 flex items-center justify-center">
              <img src={PictoIcon} className="w-8 h-8" />
            </div>
            <h1 className="text-xl font-semibold text-gray-800 mt-4">
              Essayez vos tenues depuis votre téléphone
            </h1>
            <p className="text-sm text-gray-600 mt-2">
              L’essayage virtuel utilise une photo de vous pour générer un
              aperçu du vêtement tel que vous le porteriez.
            </p>
          </div>
          <div className="mt-6 gap-4">
            <button
              onClick={handleAddPhotoClick}
              className="w-full bg-black text-white py-3 rounded-2xl font-medium shadow-md hover:bg-gray-800 transition leading-tight"
            >
              Ajouter ma photo
            </button>
            <button
              onClick={() => handleClick("/")}
              className="w-full bg-gray-100 text-dark-purple py-3 rounded-2xl mt-3 font-medium  hover:bg-gray-200 transition  border border-[#9ea1a8] leading-tight"
            >
              Plus tard
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPhotoPage;
