import React, { useEffect, useState } from "react";
import { Product as ProductModel } from "../types/product";
import { Style as StyleModel } from "../types/types";
import Product from "./Product";
import Styles from "./Styles";

interface SuggestedItemsProps {
  data: string;
}

type ItemsType = StyleModel[] | ProductModel[];

const SuggestedItems: React.FC<SuggestedItemsProps> = ({ data }) => {
  const [parsedData, setParsedData] = useState<
    ProductModel[] | StyleModel[] | null
  >(null);

  useEffect(() => {
    try {
      if (data) {
        const parsed = JSON.parse(data) as ItemsType;
        setParsedData(parsed);
      }
    } catch (error) {
      console.error("Failed to parse data JSON:", error);
      setParsedData(null);
    }
  }, [data]);

  const isProductArray = (data: ItemsType): data is ProductModel[] => {
    return (
      Array.isArray(data) &&
      data.length > 0 &&
      "amount" in data[0] &&
      !("associated_products" in data[0]) &&
      typeof (data[0] as ProductModel).amount === "object"
    );
  };

  const isStyleArray = (data: ItemsType): data is StyleModel[] => {
    return (
      Array.isArray(data) &&
      data.length > 0 &&
      "associated_products" in data[0] &&
      Array.isArray((data[0] as StyleModel).associated_products)
    );
  };

  if (!parsedData) {
    return <div>Invalid product data</div>;
  }

  if (parsedData.length === 0) {
    return <div>No items to display</div>;
  }

  return (
    <>
      {isProductArray(parsedData) ? (
        <Product productData={parsedData} />
      ) : isStyleArray(parsedData) ? (
        <Styles styleData={parsedData} />
      ) : (
        <div>Invalid or unsupported data format</div>
      )}
    </>
  );
};

export default SuggestedItems;
