import { FormattedItem, RealtimeClient } from "openai-realtime-api";
import { useCallback, useRef, useState } from "react";
import { useAnimation } from "../context/AnimationContext";
import { useCart } from "../context/CartContext";
import { WavRecorder, WavStreamPlayer } from "../lib/wavtools/index.js";
import { UseClientTools } from "./useClientTools";

interface usePushToTalkProps {
  bubblePosition: string;
  setBubblePosition: (bubblePosition: string) => void;
  setIsAssistantSpeaking: (isAssistantSpeaking: boolean) => void;
  updateConversations: (conversations: FormattedItem[]) => void;
}

export const usePushToTalk = ({
  bubblePosition,
  setBubblePosition,
  setIsAssistantSpeaking,
  updateConversations,
}: usePushToTalkProps) => {
  const { addToCart, isCartOpen, toggleCart, clearCart } = useCart();
  const { triggerAnimation, clearImage } = useAnimation();
  const wavRecorderRef = useRef(new WavRecorder({ sampleRate: 24000 }));
  const wavStreamPlayerRef = useRef(new WavStreamPlayer({ sampleRate: 24000 }));
  const [isConnected, setIsConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isUnmounting = useRef(false);
  const [hasSpoken, setHasSpoken] = useState(false);
  const [data, setData] = useState("");

  const clientRef = useRef(
    new RealtimeClient({ url: process.env.REACT_APP_RELAY_SERVER_URL })
  );

  UseClientTools({ clientRef, addToCart, triggerAnimation });

  const connectConversation = useCallback(async () => {
    if (isConnected || isUnmounting.current) return;
    try {
      const client = clientRef.current;
      const wavRecorder = wavRecorderRef.current;
      const wavStreamPlayer = wavStreamPlayerRef.current;

      if (isConnected || client.isConnected) {
        return;
      }

      if (!client.isConnected) {
        await client.realtime.connect();
      }

      updateConversations(client.conversation.getItems());

      if (wavRecorder.getStatus() === "ended") {
        await wavRecorder.begin();
      }

      if (
        !wavStreamPlayer.context ||
        wavStreamPlayer.context.state === "closed"
      ) {
        await wavStreamPlayer.connect();
      }

      client.sendUserMessageContent([
        {
          type: `input_text`,
          text: `Salut!`,
        },
      ]);

      setIsConnected(true);
    } catch (error) {
      console.error("Failed to connect:", error);
      setIsConnected(false);
    }
  }, [isConnected, setIsConnected]);

  const disconnectConversation = useCallback(async () => {
    isUnmounting.current = true;
    try {
      const client = clientRef.current;
      const wavRecorder = wavRecorderRef.current;
      const wavStreamPlayer = wavStreamPlayerRef.current;

      if (!isConnected || !client.isConnected) {
        return;
      }

      updateConversations([]);

      if (wavRecorder.getStatus() === "recording") {
        await wavRecorder.pause();
      }

      if (wavRecorder.getStatus() !== "ended") {
        await wavRecorder.quit();
      }

      if (
        wavStreamPlayer.context &&
        wavStreamPlayer.context.state !== "closed"
      ) {
        await wavStreamPlayer.interrupt();
      }

      if (client.isConnected) {
        client.realtime.disconnect();
      }

      setIsConnected(false);
    } catch (error) {
      console.error("Failed to disconnect:", error);
    }
  }, [isConnected, setIsConnected]);

  const handleConversationUpdate = useCallback(
    async ({ item, delta }: any) => {
      try {
        const client = clientRef.current;
        const items = client.conversation.getItems();
        if (delta?.audio) {
          setIsLoading(false);
          setIsAssistantSpeaking(true);
          if (bubblePosition !== "center" && !data && !isCartOpen) {
            setBubblePosition("center");
          }
          wavStreamPlayerRef.current.add16BitPCM(delta.audio, item.id);
        }
        if (item.type === "function_call") {
          setIsLoading(true);
        }

        if (
          item.type === "function_call_output" &&
          item.formatted.output !== "[]"
        ) {
          setData(item.formatted.output);
          setIsLoading(false);
          setBubblePosition("bottom");
        }

        if (item.status === "completed" && item.formatted.audio?.length) {
          const wavFile = await WavRecorder.decode(
            item.formatted.audio,
            24000,
            24000
          );
          item.formatted.file = wavFile;
        }
        updateConversations(items);
      } catch (error: unknown) {
        console.error("Error in handleConversationUpdate:", error);
      }
    },
    [setData, setIsLoading, setBubblePosition, setIsAssistantSpeaking]
  );

  return {
    clientRef,
    data,
    isLoading,
    wavRecorderRef,
    wavStreamPlayerRef,
    isConnected,
    connectConversation,
    disconnectConversation,
    handleConversationUpdate,
  };
};
