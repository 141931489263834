import React from "react";
import ActionButtons from "../components/ActionButtons";
import { MainFooter } from "../components/Footer";
import Greeting from "../components/Greeting";
import Logo from "../components/Logo";
import { UserAccountButton } from "../components/UserAccountButton";

const HomePage: React.FC = () => {
  return (
    <div className=" w-full relative flex flex-col items-center justify-center h-screen-safe   pb-[calc(16px+env(safe-area-inset-bottom))] text-center  sm:pb-10 overflow-hidden flex-shrink-0  top-0 mt-0">
      <div className="bg-radial-gradient top-0 mt-0"></div>
      <UserAccountButton />
      <Logo />
      <Greeting />
      <ActionButtons />
      <MainFooter />
    </div>
  );
};

export default HomePage;
