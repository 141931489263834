import CloseIcon from "../assets/X.svg";
import { useCart } from "../context/CartContext";
const CartScreen = () => {
  const { cart, editCart, removeFromCart, toggleCart, updateCart } = useCart();

  const handleQuantityChange = (id: string, increment: number) => {
    if (!cart) return;
    const updatedListItems = cart.listItems.map((item) => {
      if (item.id === id) {
        const newQty = item.qty + increment;
        return newQty > 0
          ? {
              ...item,
              qty: newQty,
            }
          : item;
      }
      return item;
    });

    const updatedTotalCentAmount = updatedListItems.reduce(
      (sum, item) => sum + item.lineTotal.centAmount * item.qty,
      0
    );

    cart.listItems = updatedListItems;
    cart.total.centAmount = updatedTotalCentAmount;

    editCart({
      ...cart,
      listItems: updatedListItems,
      total: {
        ...cart.total,
        centAmount: updatedTotalCentAmount,
      },
    });
  };

  return (
    <div className="fixed flex inset-0 z-50 items-center justify-center  pointer-events-auto">
      <div
        className="absolute inset-0 bg-black bg-opacity-10"
        onClick={toggleCart}
      ></div>
      <div
        className="flex items-center justify-center w-full  bg-transparent overflow-hidden p-2 flex-shrink-0 fixed z-60 mb-24 max-w-full md:max-w-md lg:max-w-lg md:min-w-sm lg:min-w-md"
        style={{ maxHeight: "75vh" }}
      >
        <div className="relative w-full bg-white flex flex-col p-2 justify-between rounded-xl shadow flex-shrink-0 h-full">
          <div className="relative flex justify-end items-center p-1">
            <button
              className="relative top-1 right-1 w-4"
              onClick={() => toggleCart()}
            >
              <img src={CloseIcon} alt="Close Icon" />
            </button>
          </div>
          <p className="flex text-md font-black  items-center justify-center text-gray-800 p-2 border-b-2">
            Votre panier
          </p>
          {cart && cart.listItems.length !== 0 ? (
            <>
              <div
                className="flex flex-col w-full bg-white overflow-y-auto overflow-x-hidden border-b-2 p-2 no-scrollbar"
                style={{
                  maxHeight: "calc(60vh - 130px)",
                }}
              >
                {cart.listItems.map((item) => (
                  <div
                    key={item.id}
                    className="w-full flex items-start space-x-4 p-2 last:border-none"
                  >
                    <img
                      src={item.image}
                      alt={item.name}
                      className="w-16 h-16 rounded object-cover"
                    />
                    <div className="flex flex-col flex-grow">
                      <h3 className="text-sm font-medium text-gray-800">
                        {item.name}
                      </h3>
                      <button
                        className="flex flex-start text-xs underline text-red-500 cursor-pointer bg-transparent border-none p-0 mt-1"
                        onClick={() => removeFromCart(item.id)}
                      >
                        Supprimer
                      </button>
                    </div>
                    <div className="text-right">
                      <p className="font-bold text-sm text-gray-800">
                        {item.lineTotal.centAmount.toFixed(2)} €
                      </p>
                      <div className="flex bg-white rounded shadow items-center space-x-2 mt-2">
                        <button
                          className="px-2 py-1 text-gray-600"
                          onClick={() => handleQuantityChange(item.id, -1)}
                        >
                          -
                        </button>
                        <span>{item.qty}</span>
                        <button
                          className="px-2 py-1 text-gray-600"
                          onClick={() => handleQuantityChange(item.id, 1)}
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="relative flex justify-between items-center p-2">
                <div className="flex flex-col items-start font-medium text-lg">
                  <span>Total</span>
                  <span className="font-bold text-2xl">
                    {cart.total.centAmount} €
                  </span>
                </div>
                <button
                  className="bg-black text-white py-3 px-6 rounded-lg"
                  onClick={() => {
                    updateCart(cart);
                  }}
                >
                  Valider
                </button>
              </div>
            </>
          ) : (
            <p className="flex  items-center justify-center  p-6">
              Votre Panier est vide
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CartScreen;
