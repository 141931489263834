export const tryStyle = async (styleId: string) => {
  const token = localStorage.getItem("auth_token");
  const url = `${process.env.REACT_APP_BFF_URL}/products/?styleId=${styleId}`;
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const { image } = await response.json();
  return `data:image/webp;base64,${image}`;
};

export const tryProduct = async (productId: string) => {
  const token = localStorage.getItem("auth_token");
  const userData = localStorage.getItem("auth_data");

  if (!userData) {
    throw new Error("User data is not found in localStorage.");
  }

  let customerId: string;
  try {
    customerId = JSON.parse(userData).id;
  } catch (error) {
    throw new Error("Failed to parse user data from localStorage.");
  }

  const url = `${process.env.REACT_APP_BFF_URL}/virtual-try-on/generate/?productId=${productId}&customerId=${customerId}`;
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error(
      `Failed to generate virtual try-on: ${response.statusText}`
    );
  }

  const { image } = await response.json();
  return `data:image/webp;base64,${image}`;
};
