import { useAuth } from "../context/AuthContext";

const Greeting = () => {
  const { authData } = useAuth();
  const firstName = authData?.firstName || "";

  return (
    <div>
      <p className="text-dark-purple font-sans text-lg mb-2">
        Bonjour {firstName?.toLocaleLowerCase() === "unknown" ? "" : firstName}
      </p>
      <p className="text-dark-purple mb-8">
        Comment puis-je vous aider aujourd'hui ?
      </p>
    </div>
  );
};

export default Greeting;
