import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import EditIcon from "../assets/Edit.svg";
import LightBulbIcon from "../assets/Lightbulb.svg";
import BackNavigation from "../components/BackNavigation";
import ConfirmationModal from "../components/ConfirmationModal";
import { useUser } from "../context/UserContext";

const PictureVisualizer = () => {
  // State to control modal visibility
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const navigate = useNavigate();

  const { user, fetchUser } = useUser();

  const handleDeletePhoto = async (): Promise<void> => {
    try {
      setDeleting(true);
      const token = localStorage.getItem("auth_token");

      const response = await fetch(
        `${process.env.REACT_APP_BFF_URL}/customers/${user?.id}/remove-profile-image`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Échec de la suppression de la photo.");
      }

      setDeleting(false);
      toast.success("Photo supprimée avec succès !");
      setIsModalOpen(false);
      fetchUser();

      // Optionally navigate to a different page
      navigate("/add-photo");
    } catch (error) {
      setDeleting(false);
      console.error("Error deleting photo:", error);
      toast.error("Échec de la suppression de la photo.");
    }
  };

  const handleCancel = (): void => {
    setIsModalOpen(false); // Close the modal without deleting
  };

  const handleEdit = (navigateTo: string): void => {
    navigate(navigateTo);
  };

  return (
    <div className="h-screen flex flex-col bg-custom-light-gray">
      <BackNavigation />
      <div className="flex-1 overflow-y-auto">
        <main className="flex flex-col items-center px-4 pt-6 bg-custom-light-gray">
          <div className="relative w-full max-w-sm rounded-2xl overflow-hidden">
            <img
              src={user?.photoUrl}
              alt="User"
              className="w-full  h-full object-cover"
            />
            <button
              className="absolute bottom-4 right-4 bg-white text-gray-800 py-2 px-4 rounded-lg flex items-center space-x-2 hover:bg-gray-100 transition shadow-inner opacity-80"
              onClick={() => handleEdit("/upload-picture?edit=true")}
            >
              <img src={EditIcon} alt="edit icon" />
              <span>Modifier</span>
            </button>
          </div>
          <div className="mt-6 px-4 text-left flex">
            <span>
              <img
                src={LightBulbIcon}
                alt="light bulb icon"
                className="flex w-8 h-8"
              />
            </span>
            <span className="flex text-sm text-gray-800 leading-relaxed p-1">
              Pour un meilleur résultat, la photo doit être en pied (un portrait
              debout visible en entier et cadré des pieds à la tête).
            </span>
          </div>
        </main>
        <footer className="px-6 py-4 bg-custom-light-gray ">
          <div className="flex flex-col items-center space-y-3">
            <button
              onClick={() => setIsModalOpen(true)}
              className="w-full bg-gray-100 text-dark-purple py-3 rounded-2xl mt-3 font-medium  hover:bg-gray-200 transition  border border-[#9ea1a8] leading-tight max-w-sm"
            >
              Supprimer
            </button>
          </div>
        </footer>
      </div>
      {isModalOpen && (
        <ConfirmationModal
          onCancel={handleCancel}
          onDelete={handleDeletePhoto}
          deleting={deleting}
        />
      )}
    </div>
  );
};

export default PictureVisualizer;
