import React from "react";

interface RealtimeBubbleProps {
  position: string;
  pulseClass: string;
}

interface PushToTalkBubbleProps {
  bubblePosition: string;
  pulseClass: string;
  disabled: boolean;
  startRecording: () => void;
  stopRecording: () => void;
}

export const RealtimeBubble: React.FC<RealtimeBubbleProps> = ({
  position,
  pulseClass,
}) => {
  return (
    <div
      className={`bubble-gradient absolute ${
        position === "center"
          ? "animate-moveAndResizeToCenter"
          : "animate-moveAndResizeToBottom"
      }`}
      style={{
        bottom: position === "center" ? "50%" : "50px",
        height: position === "center" ? "121px" : "55.10px",
        width: position === "center" ? "121px" : "55.10px",
        transform: position === "center" ? "translateY(50%)" : "translateY(0)",
      }}
    >
      <div
        className={`absolute w-full h-full bg-gradient-to-r  from-[#ff944d] via-[#fb80b9] to-[#D98BFB] rounded-full opacity-50 ${pulseClass}`}
        style={{
          transform: pulseClass.startsWith("scale") ? pulseClass : undefined,
          transition: pulseClass.startsWith("scale")
            ? "transform 0.1s ease-in-out"
            : undefined,
          background: `linear-gradient(135deg, #FF944D 0%, #FB81BA 33.33%, #D98BFB 66.67%, #FF944D 100%)`,
        }}
      ></div>
      <div
        className="absolute w-full h-full bg-gradient-to-r  from-[#ff944d] via-[#fb80b9] to-[#D98BFB] rounded-full  blur-md shadow-inner opacity-65"
        style={{
          background: `linear-gradient(135deg, #FF944D 0%, #FB81BA 33.33%, #D98BFB 66.67%, #FF944D 100%)`,
        }}
      ></div>
    </div>
  );
};

export const PushToTalkBubble: React.FC<PushToTalkBubbleProps> = ({
  bubblePosition,
  pulseClass,
  disabled,
  startRecording,
  stopRecording,
}) => {
  return (
    <div
      className={`bubble-gradient focus:outline-none focus:ring-0 focus:no-underline absolute z-30	${
        bubblePosition === "center"
          ? "animate-moveAndResizeToCenter"
          : "animate-moveAndResizeToBottom"
      }`}
      style={{
        bottom: bubblePosition === "center" ? "50%" : "50px",
        height: bubblePosition === "center" ? "121px" : "55.10px",
        width: bubblePosition === "center" ? "121px" : "55.10px",
        transform:
          bubblePosition === "center" ? "translateY(50%)" : "translateY(0)",
      }}
    >
      <div
        className={`focus:outline-none focus:ring-0 focus:no-underline absolute w-full h-full bg-gradient-to-r from-[#FF944D] via-[#FB81BA] to-[#D98BFB] rounded-full opacity-50 ${pulseClass}`}
        style={{
          transform: pulseClass.startsWith("scale") ? pulseClass : undefined,
          transition: pulseClass.startsWith("scale")
            ? "transform 0.1s ease-in-out"
            : undefined,
          background: `linear-gradient(135deg, #FF944D 0%, #FB81BA 33.33%, #D98BFB 66.67%, #FF944D 100%)`,
        }}
      ></div>
      <button
        onMouseDown={() => {
          if (bubblePosition !== "center") startRecording();
        }}
        onMouseUp={() => {
          if (bubblePosition !== "center") stopRecording();
        }}
        onTouchStart={() => {
          if (bubblePosition !== "center") startRecording();
        }}
        onTouchEnd={() => {
          if (bubblePosition !== "center") stopRecording();
        }}
        disabled={disabled}
        className={`absolute focus:outline-none focus:ring-0 focus:no-underline bg-gradient-to-r from-[#FF944D] via-[#FB81BA] to-[#D98BFB] rounded-full shadow-inner blur-md  opacity-65`}
        style={{
          height: "100%",
          width: "100%",
          background: `linear-gradient(135deg, #FF944D 0%, #FB81BA 33.33%, #D98BFB 66.67%, #FF944D 100%)`,
        }}
      ></button>
    </div>
  );
};
