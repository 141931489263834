import { FormattedItem } from "openai-realtime-api";
import { FC, PropsWithChildren, useState } from "react";
import { ConversationContext } from "../context/ConversationContext";

export const ConversationProvider: FC<PropsWithChildren> = ({ children }) => {
  const [conversations, setConversations] = useState<FormattedItem[]>([]);
  const [isConversationsModalOpen, setIsConversationsModalOpen] =
    useState(false);

  const toggleConversations = (): void => {
    setIsConversationsModalOpen(!isConversationsModalOpen);
  };

  const updateConversations = (conversations: FormattedItem[] | []): void => {
    setConversations(conversations);
  };

  const clearConversations = (): void => {
    setConversations([]);
  };

  const contextValue = {
    conversations,
    isConversationsModalOpen,
    toggleConversations,
    updateConversations,
    clearConversations,
  };

  return (
    <ConversationContext.Provider value={contextValue}>
      {children}
    </ConversationContext.Provider>
  );
};
