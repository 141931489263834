import { CloseButton } from "./CloseButton";
import { HistoryButton } from "./HistoryButton";
const AssistantHeader = () => {
  return (
    <div className="absolute w-full h-auto top-0 p-4 mt-0">
      <CloseButton />
      <HistoryButton />
    </div>
  );
};
export default AssistantHeader;
