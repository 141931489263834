import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MainFooter } from "../components/Footer";
import Logo from "../components/Logo";
import { useAuth } from "../context/AuthContext";

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { login, token } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [token, navigate]);

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!username || !password) {
      const errorMsg =
        !username && !password
          ? "Nom d'utilisateur et mot de passe sont requis!"
          : !username
          ? "Nom d'utilisateur requis!"
          : "mot de passe requis!";
      toast.error(errorMsg);
      return;
    }

    try {
      await login(username, password);
      navigate("/");
    } catch (error: any) {
      toast.error(error.message || "Une erreur s'est produite");
    }
  };

  return (
    <div className=" w-full relative flex flex-col items-center justify-center h-screen-safe   pb-[calc(16px+env(safe-area-inset-bottom))] text-center  sm:pb-10 overflow-hidden flex-shrink-0  top-0 mt-0">
      <div className="bg-radial-gradient top-0 mt-0"></div>
      <div className="w-full max-w-sm bg-white bg-opacity-10 backdrop-blur-md p-8 rounded-xl shadow-lg">
        <Logo />
        <form onSubmit={handleLogin}>
          <div className="mb-4">
            <label
              htmlFor="username"
              className="flex justify-start block text-gray-500 font-semibold mb-2"
            >
              Email
            </label>
            <input
              type="text"
              id="username"
              placeholder="Entrer votre email"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full p-3 bg-white bg-opacity-20 text-gray-500 border border-white rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-400"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="password"
              className="flex justify start block text-gray-500 font-semibold mb-2"
            >
              Mot de Passe
            </label>
            <input
              type="password"
              id="password"
              placeholder="Entrer votre mot de passe"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full p-3 bg-white bg-opacity-20 text-gray-500 border border-white rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-400"
            />
          </div>
          <button
            type="submit"
            className="w-full py-3 bg-gradient-to-r  from-[#ff944d] via-[#fb80b9] to-[#D98BFB] text-white font-bold rounded-lg  hover:from-[#e06835] hover:via-[#e1549a] hover:to-[#b569db] transition-all duration-300 text-sm"
          >
            Se connecter
          </button>
        </form>
      </div>
      <MainFooter />
    </div>
  );
};

export default LoginPage;
