import { useConversations } from "../context/ConversationContext";
export const TranscriptedConversation = () => {
  const { toggleConversations, conversations } = useConversations();

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center pointer-events-auto">
      <div
        className="absolute inset-0 bg-black bg-opacity-10"
        onClick={toggleConversations}
      ></div>

      <div className="relative w-full max-w-md  h-[75vh] max-h-[75vh] bg-custom-light-gray rounded-xl shadow-lg flex flex-col p-2 mx-4 sm:mx-auto mb-24">
        {/* Header */}
        <div className="border-b border-gray-200 bg-custom-light-gray text-lg font-bold text-gray-700 flex items-center justify-between p-2">
          <span>Conversation</span>
          <button
            aria-label="Close"
            className="text-gray-500 hover:text-gray-700 focus:outline-none"
            onClick={toggleConversations}
          >
            &times;
          </button>
        </div>

        {/* Messages Container */}
        <div className="p-4 space-y-4 overflow-y-auto flex-1 bg-custom-light-gray no-scrollbar">
          {conversations.map((conversationItem, index) => (
            <div
              key={conversationItem.id}
              className={`flex ${
                conversationItem.role === "user"
                  ? "justify-end"
                  : "justify-start"
              }`}
            >
              {!conversationItem.formatted.tool &&
                conversationItem.role === "user" && (
                  <div
                    className={`
                               bg-gray-200 text-gray-800
                               p-3 rounded-lg max-w-[80%] sm:max-w-sm w-fit`}
                  >
                    <p className="text-sm">
                      {conversationItem.formatted.transcript ||
                        (conversationItem.formatted.audio?.length
                          ? "(awaiting transcript)"
                          : conversationItem.formatted.text || "(item sent)")}
                    </p>
                  </div>
                )}

              {!conversationItem.formatted.tool &&
                conversationItem.role === "assistant" && (
                  <div
                    className={`bg-gray-900 text-white
                            p-3 rounded-lg max-w-[80%] sm:max-w-sm w-fit`}
                  >
                    <p className="text-sm">
                      {conversationItem.formatted.transcript ||
                        conversationItem.formatted.text ||
                        "(truncated)"}
                    </p>
                  </div>
                )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
