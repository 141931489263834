import React from "react";
import MagicIcon from "../assets/Magic-icon.svg";
import { useAnimation } from "../context/AnimationContext";
import { Style as StyleModel } from "../types/types";
import { tryStyle } from "../utils/tryOnImage";
import ItemCard from "./ItemCard";
import ItemsCarousel from "./ItemsCarousel";

interface StylesProps {
  styleData: StyleModel[];
}

const Styles: React.FC<StylesProps> = ({ styleData }) => {
  const { loadingStates, imageData, triggerAnimation } = useAnimation();

  if (styleData.length === 1) {
    const singleStyle = styleData[0];
    return (
      <div className="flex flex-col items-center justify-center w-full">
        <div className="w-full flex justify-center items-center  mb-24">
          <div className="w-[321px] bg-white rounded-lg shadow-lg border border-gray-200 relative">
            {loadingStates[singleStyle.id] ? (
              <div
                className=" w-full h-[65vh] animate-magicPulse inset-0 rounded-lg z-10 shadow-inner opacity-50"
                style={{
                  background: `linear-gradient(135deg, #FF944D 0%, #FB81BA 33.33%, #D98BFB 66.67%, #FF944D 100%)`,
                }}
              ></div>
            ) : (
              <img
                src={imageData[singleStyle.id] || singleStyle.image}
                alt={singleStyle.name}
                className="w-full h-[65vh] object-cover rounded-lg"
              />
            )}
            {loadingStates[singleStyle.id] ? null : (
              <button
                onClick={() =>
                  triggerAnimation(singleStyle.id, () =>
                    tryStyle(singleStyle.id)
                  )
                }
                className="absolute bottom-5 right-4 bg-custom-light-gray px-4 py-3 rounded-lg flex items-center space-x-3 opacity-75"
              >
                Essayer
                <img
                  src={MagicIcon}
                  alt="Magic Icon"
                  className="w-18 h-18 ml-1"
                />
              </button>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <ItemsCarousel>
        {styleData.map((style) => (
          <ItemCard key={style.id}>
            <div className="relative">
              {loadingStates[style.id] ? (
                <div
                  className=" w-full h-[65vh] animate-magicPulse inset-0 rounded-lg z-10 shadow-inner opacity-50"
                  style={{
                    background: `linear-gradient(135deg, #FF944D 0%, #FB81BA 33.33%, #D98BFB 66.67%, #FF944D 100%)`,
                  }}
                ></div>
              ) : (
                <img
                  src={imageData[style.id] || style.image}
                  alt={style.name}
                  className="w-full h-[65vh] object-cover rounded-lg"
                />
              )}
              {loadingStates[style.id] ? null : (
                <button
                  onClick={() =>
                    triggerAnimation(style.id, () => tryStyle(style.id))
                  }
                  className="absolute bottom-5 right-4 bg-custom-light-gray px-4 py-3 rounded-lg flex items-center space-x-3 opacity-75"
                >
                  Essayer
                  <img
                    src={MagicIcon}
                    alt="Magic Icon"
                    className="w-18 h-18 ml-1"
                  />
                </button>
              )}
            </div>
          </ItemCard>
        ))}
      </ItemsCarousel>
    );
  }
};

export default Styles;
