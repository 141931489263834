import { FC, PropsWithChildren, useCallback, useEffect, useState } from "react";
import { useAuth } from "../context/AuthContext";
import { UserContext } from "../context/UserContext";
import { User } from "../types/user";

export const UserProvider: FC<PropsWithChildren> = ({ children }) => {
  const { token, logout, authData } = useAuth(); // Get token from AuthContext
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchUser = useCallback(async () => {
    if (!token) {
      setUser(null);
      setLoading(false);
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BFF_URL}/customers/${authData?.id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        if (response.status === 401) {
          logout();
        }
        throw new Error("Failed to fetch user data.");
      }

      const userData: User = await response.json();
      setUser(userData);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [token, logout]);

  const clearUser = useCallback(() => {
    setUser(null);
  }, []);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  return (
    <UserContext.Provider
      value={{ user, loading, error, fetchUser, clearUser }}
    >
      {children}
    </UserContext.Provider>
  );
};
