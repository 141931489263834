import React from "react";

interface InteractionStatusTextProps {
  isLoading: boolean;
  showText: boolean;
  bubblePosition: string;
  hasSpoken: boolean;
}

interface PTInteractionStatusTextProps {
  isLoading: boolean;
  isPressed: boolean;
  isAssistantSpeaking: boolean;
  bubblePosition: string;
}

export const InteractionStatusText: React.FC<InteractionStatusTextProps> = ({
  isLoading,
  showText,
  bubblePosition,
  hasSpoken,
}) => {
  const getText = () => {
    if (isLoading) return "Patientez...";
    if (showText && hasSpoken) return "Je vous écoute...";
    return null;
  };

  const positionClass =
    bubblePosition === "center" ? "bottom-[30%]" : "bottom-[120px]";
  const text = getText();

  if (!text) return null;

  return (
    <span
      className={`absolute text-gray-600 text-md font-medium ${positionClass}`}
    >
      {text}
    </span>
  );
};

export const PTInteractionStatusText: React.FC<
  PTInteractionStatusTextProps
> = ({ isLoading, isPressed, isAssistantSpeaking, bubblePosition }) => {
  const getText = () => {
    if (bubblePosition === "center" && isLoading) return "Patientez...";
    if (
      bubblePosition !== "center" &&
      !isPressed &&
      !isAssistantSpeaking &&
      isLoading
    )
      return "Patientez...";
    if (
      bubblePosition !== "center" &&
      !isPressed &&
      !isAssistantSpeaking &&
      !isLoading
    )
      return "Appuyez pour parler";
    return null;
  };

  const text = getText();

  if (!text) return null;

  return (
    <span
      className={`absolute text-gray-600 text-md font-medium ${
        bubblePosition === "center" ? "bottom-[30%]" : "bottom-[120px]"
      } bottom-[120px]`}
    >
      {text}
    </span>
  );
};
