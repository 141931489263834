import React, { useEffect, useRef, useState } from "react";
import { WavRecorder, WavStreamPlayer } from "../lib/wavtools/index.js";

interface UseAudioProcessingProps {
  wavStreamPlayerRef: React.MutableRefObject<WavStreamPlayer | null>;
  wavRecorderRef: React.MutableRefObject<WavRecorder | null>;
  setShowText: (showText: boolean) => void;
  isPressed: boolean;
  enableInactivityTimer?: boolean;
}

export const useAudioProcessing = ({
  wavStreamPlayerRef,
  wavRecorderRef,
  setShowText,
  isPressed,
  enableInactivityTimer = true,
}: UseAudioProcessingProps) => {
  const inactivityDuration = 2000;
  const [pulseClass, setPulseClass] = useState("animate-pulseExpand");
  const [isInactive, setIsInactive] = useState(false);

  const inactivityTimerRef = useRef<NodeJS.Timeout | null>(null);

  const wavStreamPlayer = wavStreamPlayerRef.current;
  const wavRecorder = wavRecorderRef.current;

  useEffect(() => {
    let animationFrameId: any;

    const resetInactivityTimer = () => {
      if (!enableInactivityTimer) return; // Skip if the timer is disabled
      if (inactivityTimerRef.current) {
        clearTimeout(inactivityTimerRef.current);
      }
      setIsInactive(false);
      setShowText(false);
      inactivityTimerRef.current = setTimeout(() => {
        setIsInactive(true);
        setShowText(true);
        setPulseClass("animate-pulseExpand");
      }, inactivityDuration);
    };

    const threshold: number = 10;

    const updatePulse = () => {
      let assistantFrequency = 0;
      let userFrequency = 0;

      if (wavStreamPlayer && wavStreamPlayer?.analyser) {
        assistantFrequency = wavStreamPlayer.getAverageFrequency();
      }

      if (wavRecorder && wavRecorder?.analyser) {
        userFrequency = wavRecorder.getAverageFrequency();
      }

      if (assistantFrequency > 0 || userFrequency >= threshold) {
        if (!isPressed) {
          if (enableInactivityTimer) resetInactivityTimer();
          const scaleValue = Math.min(
            1 + Math.max(assistantFrequency, userFrequency) / 128,
            1.5
          );
          setPulseClass(`scale(${scaleValue})`);
        }
      } else if (isPressed) {
        if (enableInactivityTimer) resetInactivityTimer();
        setPulseClass("animate-fastPulseExpand");
      } else if (!isInactive) {
        setPulseClass("animate-pulseExpand");
      }
      animationFrameId = requestAnimationFrame(updatePulse);
    };

    updatePulse();

    return () => {
      cancelAnimationFrame(animationFrameId);
      if (inactivityTimerRef.current) clearTimeout(inactivityTimerRef.current);
    };
  }, [wavStreamPlayer, wavRecorder, isPressed, enableInactivityTimer]);

  return { pulseClass };
};
