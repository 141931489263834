import { createContext, useContext } from "react";
import { Cart } from "../types/cart";

export interface CartContextType {
  cart: Cart | null;
  isCartOpen: boolean;
  toggleCart: () => void;
  addToCart: (productId: string) => Promise<void>;
  clearCart: () => Promise<void>;
  editCart: (cart: Cart | null) => void;
  removeFromCart: (lineItemId: string) => Promise<void>;
  updateCart: (cart: Cart) => Promise<void>;
}

export const CartContext = createContext<CartContextType>({
  cart: null,
  isCartOpen: false,
  toggleCart: () => {},
  addToCart: async () => {},
  clearCart: async () => {},
  editCart: () => {},
  removeFromCart: async () => {},
  updateCart: async () => {},
});

export const useCart = () => useContext(CartContext);
