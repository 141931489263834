import { FC, PropsWithChildren, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { CartContext, CartContextType } from "../context/CartContext";
import { Cart } from "../types/cart";

export const CartProvider: FC<PropsWithChildren> = ({ children }) => {
  const [cart, setCart] = useState<Cart | null>(null);
  const [isCartOpen, setIsCartOpen] = useState<boolean>(false);

  useEffect(() => {
    const savedCartId = localStorage.getItem("cartId");
    if (savedCartId) {
      fetchCart(savedCartId);
    }
  }, []);

  const fetchCart = async (cartId: string): Promise<void> => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BFF_URL}/cart/?cartId=${cartId}`
      );
      if (!response.ok) throw new Error("Failed to fetch cart");
      const cartData: Cart = await response.json();
      setCart(cartData);
    } catch (error) {
      console.error("Error fetching cart:", error);
    }
  };

  const addToCart = async (productId: string): Promise<void> => {
    try {
      const cartId = cart?.id || localStorage.getItem("cartId");
      const response = await fetch(`${process.env.REACT_APP_BFF_URL}/cart`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ cartId, productId }),
      });

      if (!response.ok) throw new Error("Failed to add to cart");
      const updatedCart: Cart = await response.json();
      setCart(updatedCart);
      localStorage.setItem("cartId", updatedCart.id);
    } catch (error) {
      console.error("Error adding to cart:", error);
    }
  };

  const clearCart = async (): Promise<void> => {
    localStorage.removeItem("cartId");
    setCart(null);
  };

  const toggleCart = (): void => setIsCartOpen(!isCartOpen);

  const editCart = (cart: Cart | null): void => {
    cart === null ? clearCart() : setCart(cart);
  };

  const removeFromCart = async (lineItemId: string): Promise<void> => {
    try {
      const cartId = localStorage.getItem("cartId");
      if (!cartId) throw new Error("Cart ID is required");

      const response = await fetch(
        `${process.env.REACT_APP_BFF_URL}/cart/remove`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ cartId, lineItemId }),
        }
      );

      /*if (!response.ok) throw new Error("Failed to remove item from cart");
      await fetchCart(cartId);*/
      if (!response.ok) throw new Error("Failed to add to cart");
      const updatedCart: Cart = await response.json();
      setCart(updatedCart);
      localStorage.setItem("cartId", updatedCart.id);
    } catch (error) {
      console.error("Error removing item from cart:", error);
    }
  };

  const updateCart = async (cart: Cart): Promise<void> => {
    try {
      const cartId = localStorage.getItem("cartId");

      const newCartItems = cart.listItems.map((cartItem) => {
        return { lineItemId: cartItem.id, quantity: cartItem.qty };
      });

      const response = await fetch(
        `${process.env.REACT_APP_BFF_URL}/cart/update-quantity`,
        {
          method: "Post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ cartId, items: newCartItems }),
        }
      );

      if (!response.ok) throw new Error("Failed to add to cart");
      const updatedCart: Cart = await response.json();
      setCart(updatedCart);
      localStorage.setItem("cartId", updatedCart.id);
      toggleCart();
      toast.success("Votre panier a bien été enregistré!");
    } catch (error) {
      console.error("Error updating  cart:", error);
    }
    setCart(cart);
  };

  const contextValue: CartContextType = {
    cart,
    isCartOpen,
    toggleCart,
    addToCart,
    clearCart,
    editCart,
    removeFromCart,
    updateCart,
  };

  return (
    <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>
  );
};
