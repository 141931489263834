import { useUser } from "../context/UserContext";
import AddPhotoPage from "../pages/AddPhotoPage";
import PictureVisualizer from "../pages/PictureVisualizer";
import Loader from "./Loader";

const UserPicture = () => {
  const { user, loading } = useUser();
  if (loading) {
    return <Loader />;
  }
  if (user && user?.photoUrl) {
    return <PictureVisualizer />;
  }
  return <AddPhotoPage />;
};
export default UserPicture;
