import ReactLoading from "react-loading";

const Loader = () => {
  return (
    <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
      <ReactLoading type="bubbles" color="#FF944D" />
    </div>
  );
};

export default Loader;
