import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "../assets/ArrowBack.svg";
import { useUser } from "../context/UserContext";

const BackNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useUser();

  const handleNavigation = () => {
    if (location.pathname === "/terms-and-conditions") {
      navigate("/add-photo");
    }
    if (location.pathname === "/upload-picture") {
      if (user?.photoUrl) {
        navigate("/picture-visualizer");
      } else {
        navigate("/add-photo");
      }
    }
    if (location.pathname === "/picture-visualizer") {
      navigate("/");
    }
    if (location.pathname === "/user-picture") {
      if (user?.photoUrl) {
        navigate("/");
      } else {
        navigate("/add-photo");
      }
    }
  };

  return (
    <header className="flex items-center px-4 py-4 border-b border-gray-200 bg-white">
      <button
        className="text-gray-800 flex items-center"
        onClick={handleNavigation}
      >
        <img src={ArrowBackIcon} alt="back arrow icon" />
        <h6 className="ml-3 text-sm font-semibold text-gray-800">Retour</h6>
      </button>
    </header>
  );
};

export default BackNavigation;
